<template>
  <k-layout no-header no-footer class="tw-bg-gray-200">
    <template #default>
      <k-container class="tw-py-16">
        <template #default>
          <div class="tw-w-full tw-mx-auto tw-max-w-3xl tw-p-8 tw-bg-white">
            <k-logo class="tw-h-12 tw-object-left" />

            <div class="tw-mt-8">
              <h1 class="tw-heading-2">{{ $t('profile.subtitles.email_settings') }}</h1>
              <p class="tw-text-xl tw-text-gray-500 tw-mt-2">{{ $route.query.email }}</p>
            </div>

            <template v-if="status === 'PENDING'">
              <div class="tw-flex tw-justify-center tw-py-16">
                <v-progress-circular :size="48" class="tw-text-gray-500" indeterminate></v-progress-circular>
              </div>
            </template>

            <template v-else-if="status === 'SUCCESS'">
              <div
                v-for="(topic, idx) in topics"
                :key="topic.title"
                class="tw-border-t tw-pt-8 tw-border-gray-200"
                :class="{
                  'tw-mt-8': idx === 0
                }"
              >
                <h2 class="tw-heading-4">{{ topic.title }}</h2>
                <ul class="tw-mt-5 tw-m-0 tw-p-0 tw-list-none tw-divide-y tw-divide-gray-200">
                  <li
                    class="tw-flex tw-items-center tw-justify-between tw-py-3"
                    v-for="(subscriptionTitle, subscriptionName) in topic.subscriptions"
                    :key="subscriptionName"
                  >
                    <p class="">{{ subscriptionTitle }}</p>
                    <v-switch
                      v-model="subscriptions[subscriptionName]"
                      :value="subscriptions[subscriptionName]"
                      color="#0EA66D"
                      inset
                      hide-details
                      class="tw-m-0 tw-p-0 tw-w-11"
                    />
                  </li>
                </ul>
              </div>

              <div class="tw-border-t tw-pt-8 tw-border-gray-200 tw-flex tw-items-center tw-justify-between">
                <k-button @click="saveSubscriptions(subscriptions)" class="btn-success" type="button" :width="100">
                  {{ $t('global.actions.save') }}
                </k-button>

                <k-link to="/" class="tw-text-gray-600 hover:tw-text-gray-800">{{ $t('navigation.home') }} ›</k-link>
              </div>
            </template>

            <template v-else-if="status === 'ERROR'">
              <div class="tw-border-t tw-pt-8 tw-border-gray-200 tw-mt-8">
                <h2 class="tw-heading-4">{{ $t('profile.subtitles.invalid_link') }}</h2>
                <p class="tw-mt-6">{{ $t('subscribe.descriptions.invalid_link') }}</p>
              </div>
            </template>

            <template v-else></template>
          </div>
        </template>
      </k-container>
    </template>
  </k-layout>
</template>

<script>
import urlcat from 'urlcat'

import config from '@shared/config'

const TOPICS = [
  {
    title: 'Pracovné ponuky',
    subscriptions: {
      'subscription-jobs-personalised-ads': 'Pracovná ponuka vybraná pre mňa',
      'subscription-jobs-reminder-not-opened': 'Upozornenie po neotvorení novej pracovnej ponuky',
      'subscription-jobs-weekly-summary': 'Týždenný súhrn pracovných ponúk'
    }
  },
  {
    title: 'Články, blogy, podcasty a novinky',
    subscriptions: {
      'subscription-news-personalised-ads': 'Nový blog, článok alebo podcast',
      'subscription-news-weekly-summary': 'Týždenný newsfilter zo zahraničných portálov',
      'subscription-news-general': 'Všeobecné novinky Koderie'
    }
  },
  {
    title: 'Podujatia',
    subscriptions: {
      'subscription-events-personalised-ads': 'Nové podujatie podľa mojich záujmov',
      'subscription-events-weekly-summary': 'Týždenný výber podujatí'
    }
  }
]

export default {
  data() {
    const subscriptions = {}

    TOPICS.forEach(topic => {
      Object.keys(topic.subscriptions).forEach(subscriptionName => {
        subscriptions[subscriptionName] = false
      })
    })

    return {
      status: 'IDLE',
      subscriptions: subscriptions,
      topics: TOPICS
    }
  },

  mounted() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.status = 'PENDING'
      try {
        const subscriptions = await this.getSubscriptions()
        Object.entries(subscriptions).forEach(([subscriptionName, subscriptionValue]) => {
          this.subscriptions[subscriptionName] = !!subscriptionValue
        })
        this.status = 'SUCCESS'
      } catch (err) {
        this.status = 'ERROR'
      }
    },

    getSubscriptionsUrl() {
      return urlcat(config.adminUrl, 'api/external/subscriptions', {
        key: this.$route.query.key,
        email: this.$route.query.email
      })
    },
    async getSubscriptions() {
      const subscriptionsUrl = this.getSubscriptionsUrl()
      const response = await fetch(subscriptionsUrl)

      const json = await response.json()

      if (response.status < 200 || response.status > 299) {
        throw json
      }

      return json
    },
    async saveSubscriptions(subscriptions) {
      const subscriptionsUrl = this.getSubscriptionsUrl()
      await fetch(subscriptionsUrl, { method: 'post', body: JSON.stringify(subscriptions) })
      this.initialize()
    }
  }
}
</script>
